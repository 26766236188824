import React from 'react';
import Navbar from './navbar';
import Section1 from './Section1';
import Section2 from './Section2';
import Section3 from './Section3';
import Section4 from './Section4';
import Section5 from './Section5';
import TeamSection from './TeamSection';
import Footer from './Footer';
import CalendlyEmbed from './CalendlyEmbed.tsx';
import Section6 from './Section6';
import Quastions from './Quastions';
import Pricing from './Pricing';

export default function MainPage() {

    return (


        <>
            <div>


                <div id='section1' style={{ backgroundImage: "url('/pictures/bg11.png')", backgroundSize: "cover" }}>
                    <Navbar />
                    <Section1 />
                </div>


                <div id='section2' style={{ backgroundImage: "url('/pictures/bgSection2.png')", backgroundSize: "cover", height: "59.5rem" }}>
                    <Section2 />
                </div>

                <div id='section2' style={{ backgroundImage: "url('/pictures/bgSection2.png')", backgroundSize: "cover", height: "54rem" }}>
                    <Section3 />
                </div>

                <div className='pb-10 ' id='section4' style={{ backgroundColor: "#D1EBE1", backgroundSize: "cover" }}>
                    <Section4 />
                </div>

                <div className='pb-10' id='section5' style={{ backgroundColor: "#D1EBE1", backgroundSize: "cover" }}>
                    <Section5 />
                </div>

                <div style={{ backgroundColor: "#D1EBE1", height: "970px" ,backgroundSize: "cover"}}>
                    <Section6 />
                </div>


                <div style={{ backgroundColor: "#D1EBE1", height: "670px" }}>
                    <Quastions />
                </div>


                <div style={{ backgroundColor: "#D1EBE1" }}>
                    <Pricing />
                </div>

                <div id='Calendly' style={{ backgroundColor: "#D1EBE1", height: "770px" ,backgroundSize: "cover" }}>
                    <CalendlyEmbed />
                </div>

                <div style={{ backgroundColor: "#003333" }}>
                    <Footer />
                </div>


            </div>



        </>





    )
}
