import React from 'react';

const Section2 = () => {
  return (

    <>


      <div className="flex flex-col md:flex-col md:items-center w-[100%] mx-auto mb-10">

        <div className='flex flex-row w-[70%]'>
          <div className='pt-14'>
            <img src="/pictures/victor2.svg" alt="" srcset="" />
          </div>
          <div className="w-full md:w-[80%] pt-10 text-left">
            <p className='text-4xl md:text-5xl font-bold text-left md:text-center text-[#003333] p-2'>
              Manage your audit processes effectively.
            </p>
            <p className='text-2xl md:text-2xl text-left md:text-center text-gray-400  pt-2 pb-8'>
              Optimise Your Agricultural Operations with <span className='bold text-sidatsGreen'>Agri In </span>   
            </p>
          </div>
        </div>


        <div className="flex justify-center pt-1">
          <img src="/pictures/platformPic.svg" alt="Image" className='w-[108rem] ' />
        </div>
      </div>


    </>


  );
};

export default Section2;