import React from 'react';

const SupportSection = ({ title, items }) => {
  return (
    <div>
      <p className='text-2xl text-[#FFFFFF] text-left pb-4'>{title}</p>
      {items.map((item, index) => (
        <p key={index} className='text-1xl text-[#A6A6A6] text-left pb-4'>
          {item}
        </p>
      ))}
    </div>
  );
};

export default SupportSection;
