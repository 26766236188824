import React from 'react';

export default function Section3() {
    return (
        <>

            <div className='container mx-auto'>
                <div className='flex flex-col items-center justify-center pt-10'>
                    <h1 className='text-5xl font-bold text-center text-sidatsGreen mt-10'>Agri In & Agricultural process efficiency</h1>
                    <p className='text-xl text-center text-base font-normal text-seconderyColor mt-10'>
                        Starting from the complexities the processes are introducing
                    </p>
                </div>

                <div className='flex flex-row items-center justify-around gap-10 h-[379px] mt-20'>
                    <img src='/pictures/section3.svg' alt='section3' className='mt-10 h-auto' />

                    <p className='font-extralight italic font-[Inter] text-3xl text-left text-sidatsGreen mt-10 leading-relaxed'>
                        Agri In , is dedicated to transforming agricultural <br /> auditing management. Our mission is to empower <br /> agricultural businesses by offering advanced tools <br /> that ensure productivity, compliance, data <br /> availability and security. <br />
                        Agri In  is our answer to the complex challenges of <br /> modern agricultural auditing, providing an intuitive <br /> platform operations.
                    </p>

                </div>
            </div>



        </>
    );
}
