import React from "react";

export default function Pricing() {
  return (
    <>
      <div className="container mx-auto">
        <div className="flex flex-col items-center justify-center pt-10">
          <h1 className="text-5xl font-bold text-center text-black ">
            Plans & Pricing
          </h1>
          <p className="text-xl text-center text-base font-normal text-gray-500 mt-10">
            Affordable Pricing for All Farms and Agricultural <br /> Businesses
          </p>
        </div>

        <div className="flex flex-row items-center relative mx-auto w-[70%] bg-white gap-10 mt-20 p-10 rounded-lg shadow-lg">
          {/* Basic Card */}
          <div className="p-6 relative z-0">
            <h1 className="text-5xl font-bold text-left text-sidatsGreen mt-1">
              Basic
            </h1>
            <h3 className="text-2xl font-bold text-left text-sidatsGreen mt-8">
              Small
            </h3>
            <p className="text-xl text-left text-base font-normal text-gray-500 mt-1">
              Designed for startups and <br /> small farms
            </p>
            <div className="flex flex-row items-center gap-3 mt-2 ">
              <img src="/check-circle-1.svg" alt="" />
              <p className="text-xl text-left text-base font-normal text-gray-500">
                Essential features
              </p>
            </div>
            <div className="flex flex-row items-center gap-3 mt-2 ">
              <img src="/check-circle-1.svg" alt="" />
              <p className="text-xl text-left text-base font-normal text-gray-500">
                Access to audit libraries
              </p>
            </div>

            <button
              type="button"
              className="py-2.5 px-5 mt-10 text-sm font-medium bg-sidatsGreen focus:outline-none rounded-full border text-white w-[99%]"
            >
              <a href="#Calendly">Discover plan</a>
            </button>
          </div>

          {/* Standard Card */}
          <div className="p-6 relative z-0">
            <h1 className="text-5xl font-bold text-left text-sidatsGreen mt-1">
              Standard
            </h1>
            <h3 className="text-2xl font-bold text-left text-sidatsGreen mt-8">
              Mid-size
            </h3>
            <p className="text-xl text-left text-base font-normal text-gray-500 mt-1">
              Best suited for medium-sized <br /> operations
            </p>
            <div className="flex flex-row items-center gap-3 mt-2 ">
              <img src="/check-circle-1.svg" alt="" />
              <p className="text-xl text-left text-base font-normal text-gray-500">
                Basic features
              </p>
            </div>
            <div className="flex flex-row items-center gap-3 mt-2 ">
              <img src="/check-circle-1.svg" alt="" />
              <p className="text-xl text-left text-base font-normal text-gray-500">
                Template builder
              </p>
            </div>
            <div className="flex flex-row items-center gap-3 mt-2 ">
              <img src="/check-circle-1.svg" alt="" />
              <p className="text-xl text-left text-base font-normal text-gray-500">
                Teams
              </p>
            </div>

            <button
              type="button"
              className="py-2.5 px-5 mt-8 text-sm font-medium bg-sidatsGreen focus:outline-none rounded-full border text-white w-[99%]"
            >
              <a href="#Calendly">Discover plan</a>
            </button>
          </div>

          {/* Premium Card */}
          <div className="absolute z-10 right-0 bg-sidatsGreen text-white p-8 w-[30%] rounded-lg shadow-lg transform scale-105 h-full flex flex-col items-start">
            <div className="w-[60%] ">
              <div
                type="button"
                className="p-1 text-sm bg-white rounded-full border text-sidatsGreen"
              >
                <a href="#Calendly">MOST Powerfull</a>
              </div>
            </div>
            <h1 className="text-5xl font-bold text-left mt-1">Premium</h1>
            <h3 className="text-2xl font-bold text-left mt-8">Large-size</h3>
            <p className="text-xl text-left text-base font-normal mt-1">
              Tailored for large-scale <br /> agribusinesses
            </p>
            <div className="flex flex-row items-center gap-3 mt-2 ">
              <img src="/check-circle-premium.svg" alt="" />
              <p className="text-xl text-left text-base font-normal">
                Full access
              </p>
            </div>
            <div className="flex flex-row items-center gap-3 mt-2 ">
              <img src="/check-circle-premium.svg" alt="" />
              <p className="text-xl text-left text-base font-normal">
                Audit planners
              </p>
            </div>
            <div className="flex flex-row items-center gap-3 mt-2 ">
              <img src="/check-circle-premium.svg" alt="" />
              <p className="text-xl text-left text-base font-normal">
                External Auditors access
              </p>
            </div>
            <div className="flex flex-row items-center gap-3 mt-2 ">
              <img src="/check-circle-premium.svg" alt="" />
              <p className="text-xl text-left text-base font-normal">
                Support 7/7
              </p>
            </div>
            <button
              type="button"
              className="py-2.5 px-5 mt-8 text-sm font-medium bg-gray-300 focus:outline-none rounded-full border text-sidatsGreen w-[99%]"
            >
              <a href="#Calendly">Discover plan</a>
            </button>
          </div>
        </div>
      </div>
    </>
  );
}
