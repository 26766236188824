import React from 'react'
import { useState } from 'react';
import Buttons from './Button';

export default function Section1() {
  return (

    <>

      <div className="flex flex-col md:flex-row justify-around items-center h-auto md:h-160 mt-10 w-[90%] mx-auto">
        <div className="w-full md:w-2/3 p-4 text-center">
          <div className="text-5xl md:text-8xl font-bold text-left text-[#003333]">
            We’re here to <br /> Increase your <br /> audit conformity
          </div>

          <div className='w-full md:w-[680px] pt-8'>
            <img src="/pictures/Vector.png" alt="img" className="mx-auto md:mx-0" />
          </div>

          <div className='mt-5 text-xl md:text-3xl text-left'>
          Discover Agri In, the innovative SaaS platform developed for streamlining agricultural audits, enhancing productivity, and ensuring compliance with industry standards.
          </div>

          <Buttons />
        </div>

        <div className="w-full h-[600px] md:w-auto p-4 text-center hidden md:block">
          <div>
            <img src="/pictures/Section1.svg" alt="img" className="mx-auto animated-image" />
          </div>
        </div>
      </div>



    </>

  )
}
