import React, { useState } from 'react';
import Form from './Form'
import SupportSection from './SupportSection'
import Alert from 'react-bootstrap/Alert'
import Button from 'react-bootstrap/Button';

export default function () {

    const [showAlert, setShowAlert] = useState(false);

    return (

        <>




            <div className='flex flex-col md:flex-row justify-around w-[90%] mx-auto pt-20'>

                <div className="w-full md:w-1/2">
                    <div className='mt-8 flex flex-col '>
                        <div>
                            <a href="https://www.linkedin.com/company/agriin/" target="_blank" rel="noopener noreferrer">
                                <p className='text-4xl md:text-5xl text-white text-start '>Agri In</p>
                            </a>
                        </div>


                        <p className='text-base md:text-xl text-white text-left p-3'>
                            Essayez notre produit maintenant
                        </p>

                        {showAlert && (
                            <>

                                <div className="alert-overlay rounded relative">
                                    <Alert showAlert={showAlert} variant="success" className="alert-box relative">
                                        {/* Close button with z-index control */}
                                        <Button className="absolute top-[-40px] right-[-198px] z-100 mt-2 mr-2" onClick={() => setShowAlert(false)} variant="outline-success">
                                            <img src="/closeMe.svg" alt="Close button" />
                                        </Button>

                                        {/* Logo and content */}
                                        <div className='logo'>
                                            <img src="/LogoWhite.svg" alt="Logo" />
                                        </div>

                                        <Alert.Heading className='text-white'>Ooops...</Alert.Heading>
                                        <p className='p-2 text-white'>
                                            This functionality is not available yet. Our team is working on it.We will let you know when it’s available.
                                        </p>
                                    </Alert>
                                </div>

                            </>
                        )}

                        <div className="relative flex items-center w-full md:w-1/2 h-16 rounded-full" >
                            <input
                                type="text"
                                className="flex-grow border border-white text-white rounded-full pl-4 md:pl-12 pr-16 md:pr-20 py-2 focus:border-blue-500 dark:border-gray-600 dark:placeholder-gray-400 dark:focus:ring-blue-500 dark:focus:border-blue-500"
                                placeholder="Entrer votre Email"
                                style={{ backgroundColor: "#003333" }}
                            />
                            <button onClick={() => setShowAlert(true)} className="absolute right-3 md:right-4 flex items-center justify-center w-7 h-7 rounded-full bg-sidatsGreen">
                                <img src="pictures/Submit.svg" alt="row" className="w-9 h-9" />
                            </button>
                        </div>


                    </div>


                    <div className='flex flex-col md:flex-row justify-between gap-8 md:gap-20 mt-8'>
                        <SupportSection
                            title="Support"
                            items={[
                                'Help centre',
                                'Account information',
                                'About',
                                'Contact us',
                            ]}
                        />
                        <SupportSection
                            title="Help and Solution"
                            items={[
                                'Talk to support',
                                'Support docs',
                                'System status',
                                'Covid responde',
                            ]}
                        />
                        <SupportSection
                            title="Product"
                            items={[
                                'Update',
                                'Security',
                                'Beta test',
                                'Pricing products',
                            ]}
                        />
                    </div>
                </div>

                <div className="w-full md:w-[35rem] mt-10 md:mt-0">
                    <Form />
                </div>
            </div>

            <div className='flex flex-col md:flex-row justify-around items-center p-4'>
                <div className='flex justify-center items-center h-16'>
                    <p className='text-white text-center md:text-left'>
                        © 2024 Agri In Inc. Copyright and rights reserved
                    </p>
                </div>
                <div className='flex justify-center items-center h-16 mt-4 md:mt-0'>
                    <p className='text-white text-center'>
                        Privacy Policy | Terms of Service
                    </p>
                </div>
            </div>


        </>
    )
}
