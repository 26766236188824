import React from "react";
import { InlineWidget } from "react-calendly";





const CalendlyEmbed = () => {
  return (
    <div className="App pt-20">
      
      <InlineWidget url="https://calendly.com/hassan-nouhi-agriin/30min" />
    </div>
  );
};







export default CalendlyEmbed;