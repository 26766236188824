import React, { useState } from 'react';
import Button from './Button';

export default function Navbar() {
  const [isOpen, setIsOpen] = useState(false);

  const toggleMenu = () => {
    setIsOpen(!isOpen);
  };

  return (
    <div className="flex justify-between items-center w-[75%] mx-auto p-4 pt-8">
      <div className="flex-shrink-0">
        <a href="https://www.linkedin.com/company/agriin/" target="_blank" rel="noopener noreferrer">
          <img src='/pictures/Logo.svg' alt='logo' className="sm:w-[41px] sm:[48] w-9" />
        </a>
      </div>

      {/* Burger Icon (shown on small screens) */}
      <div className="md:hidden">
        <button onClick={toggleMenu} className="text-black focus:outline-none">
          <svg className="w-10 h-10 pt-2" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
            <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M4 6h16M4 12h16M4 18h16"></path>
          </svg>
        </button>
      </div>

      {/* Menu Items */}
      <ul className={`flex-col absolute top-16 left-0 w-full bg-sidatsGreen md:bg-transparent md:text-black text-white md:relative md:top-auto md:left-auto md:w-auto md:flex md:flex-row md:space-x-4 md:gap-9 ${isOpen ? 'block' : 'hidden'} md:block`}>
        <li className="p-4 border-b md:border-none"><a href="#section1">Home</a></li>
        <li className="p-4 border-b md:border-none"><a href="#section2">Services</a></li>
        <li className="p-4 border-b md:border-none"><a href="#section3">FAQ</a></li>
        <li className="p-4 border-b md:border-none"><a href="#section4">Contact</a></li>
      </ul>



      <div>
        <button type="button" className="py-2.5 px-5 me-2 mb-2 text-sm font-medium bg-sidatsGreen focus:outline-none rounded-full border text-white">
          <a href="#Calendly">
            Book Appointment

          </a>
        </button>
      </div>
    </div>
  );
}
