import React, { useState } from 'react';
import Popup from 'reactjs-popup';
import 'reactjs-popup/dist/index.css';
import Alert from 'react-bootstrap/Alert'
import Button from 'react-bootstrap/Button';
export default function ContactForm() {


    const [email, setEmail] = useState('');
    const [message, setMessage] = useState('');
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState('');
    const [showAlert, setShowAlert] = useState(false);


    const handleSubmit = async (event) => {
        event.preventDefault();

        setTimeout(() => {
            setLoading(false);
            setShowAlert(true); // Show alert after submission
        }, 2000);

        if (!email || !message) {
            setError('Please fill out both fields.');
            return;
        }

        if (!/\S+@\S+\.\S+/.test(email)) {
            setError('Please enter a valid email address.');
            return;
        }

        setError('');
        setLoading(true);

        try {

            const response = await fetch('https://your-api.com/endpoint', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({ email, message }),
            });

            if (!response.ok) {
                throw new Error('Something went wrong. Please try again.');
            }

            alert('Demo request submitted successfully!');
        } catch (error) {
            setError(error.message);
        } finally {
            setLoading(false);
        }
    };






    return (
        <>

            <div style={{ height: "30rem" }} className='pt-10 bg-formColor rounded-3xl'>
                <div className='flex justify-center'>
                    <a href="https://www.linkedin.com/company/agriin/" target="_blank" rel="noopener noreferrer">
                        <img src="/pictures/Logo.svg" alt="" srcset="" />
                    </a>
                </div>
                <div className='flex justify-center pt-5'>
                    <p className='text-2xl'>Commencez</p>

                </div>

                {showAlert && (
                    <>

                        <div className="alert-overlay rounded relative">
                            <Alert showAlert={showAlert} variant="success" className="alert-box relative">
                                {/* Close button with z-index control */}
                                <Button className="absolute top-[-40px] right-[-198px] z-100 mt-2 mr-2" onClick={() => setShowAlert(false)} variant="outline-success">
                                    <img src="/closeMe.svg" alt="Close button" />
                                </Button>

                                {/* Logo and content */}
                                <div className='logo'>
                                    <img src="/LogoWhite.svg" alt="Logo" />
                                </div>

                                <Alert.Heading className='text-white'>Ooops...</Alert.Heading>
                                <p className='p-2 text-white'>
                                    This functionality is not available yet. Our team is working on it.We will let you know when it’s available.
                                </p>
                            </Alert>
                        </div>


                    </>
                )}

                <form onSubmit={handleSubmit}>
                    <div className='max-w-sm mx-auto px-4'>
                        <div className="mb-5">
                            <label htmlFor="email" className="block mb-2 text-sm font-medium text-black text-left">Email</label>
                            <input
                                type="email"
                                id="email"
                                className="bg-white text-black text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:border-gray-600 dark:placeholder-gray-400 dark:text-black dark:focus:ring-blue-500 dark:focus:border-blue-500"
                                placeholder="name@email.com"
                                value={email}
                                onChange={(e) => setEmail(e.target.value)}
                                required
                            />
                        </div>

                        <div className="mb-5">
                            <label htmlFor="large-input" className="block mb-2 text-sm font-medium text-black text-left">Message</label>
                            <input
                                type="text"
                                id="large-input"
                                className="block w-full p-4 text-black rounded-lg bg-white text-base focus:ring-blue-500 focus:border-blue-500 dark:border-gray-600 dark:placeholder-gray-400 dark:text-black dark:focus:ring-blue-500 dark:focus:border-blue-500"
                                placeholder="Your Message"
                                value={message}
                                onChange={(e) => setMessage(e.target.value)}
                                required
                            />
                        </div>
                    </div>

                    {error && <p className='text-red-500 text-center'>{error}</p>}

                    <button
                        className='block w-[64%] p-4 text-white border border-gray-300 rounded-[40px] bg-gray-50 text-base focus:ring-blue-500 focus:border-blue-500 dark:bg-sidatsGreen max-w-sm mx-auto'
                        disabled={loading}
                    >
                        {loading ? 'Loading ...' : 'Request Demo'}
                    </button>
                </form>
                {/* <form onSubmit={handleSubmit}>
                <div className='max-w-sm mx-auto px-4'>
                    <div className="mb-5">
                        <label htmlFor="email" className="block mb-2 text-sm font-medium text-black text-left">Email</label>
                        <input
                            type="email"
                            id="email"
                            className="bg-white border border-gray-300 text-black text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:border-gray-600 dark:placeholder-gray-400 dark:text-black dark:focus:ring-blue-500 dark:focus:border-blue-500"
                            placeholder="name@email.com"
                            value={email}
                            onChange={(e) => setEmail(e.target.value)}
                            required
                        />
                    </div>

                    <div className="mb-5">
                        <label htmlFor="large-input" className="block mb-2 text-sm font-medium text-black text-left">Message</label>
                        <input
                            type="text"
                            id="large-input"
                            className="block w-full p-4 text-black border border-gray-300 rounded-lg bg-white text-base focus:ring-blue-500 focus:border-blue-500 dark:border-gray-600 dark:placeholder-gray-400 dark:text-black dark:focus:ring-blue-500 dark:focus:border-blue-500"
                            placeholder="Your Message"
                            value={message}
                            onChange={(e) => setMessage(e.target.value)}
                            required
                        />
                    </div>
                </div>

                {error && <p className='text-red-500 text-center'>{error}</p>}

                <button
                    className='block w-[64%] p-4 text-white border border-gray-300 rounded-lg bg-gray-50 text-base focus:ring-blue-500 focus:border-blue-500 dark:bg-sidatsGreen max-w-sm mx-auto'
                    // onClick={() => alert('Demo request submission coming soon ...!')}
                    disabled={loading}
                >
                    {loading ?

                        'Loading ...'

                        : 'Request Demo'}
                </button>

            </form> */}

            </div>
        </>
    )
}
