
import React, { useState } from "react";

const questions = [
    {
        question: "What is a Payment Gateway?",
        answer: "A payment gateway is a technology that captures and transfers payment data from the customer to the acquiring bank.",
    },
    {
        question: "Do I need to pay to Instapay even when there is no transaction going on in my business?",
        answer:
            "No, you do not need to pay Instapay when there is no transaction happening. With one of the lowest transaction charges in the industry, pay only when you get paid!",
    },
    {
        question: "What platforms does ACME payment gateway support?",
        answer: "ACME supports a variety of platforms including web, mobile, and POS.",
    },
    {
        question: "Does ACME provide international payments support?",
        answer: "Yes, ACME supports international payments in multiple currencies.",
    },
    {
        question: "Is there any setup fee or annual maintenance fee that I need to pay regularly?",
        answer: "No, there are no setup fees or annual maintenance fees with ACME.",
    },
];

const FAQItem = ({ question, index, isActive, onClick }) => (
    <div
        className={`flex items-center border-b p-4 cursor-pointer hover:bg-gray-100 ${isActive ? "bg-gray-100" : ""}`}
        onClick={() => onClick(index)}
    >
        {/* Custom Circular Checkbox */}
        <div className="relative">
            <input
                type="checkbox"
                className="absolute opacity-0 w-0 h-0"
                checked={isActive}
                readOnly
            />
            <div className={`w-5 h-5 border-2 border-sidatsGreen rounded-full flex items-center justify-center ${isActive ? "bg-sidatsGreen" : ""}`}>
                {isActive && <div className="w-0 h-0 bg-white rounded-full"></div>}
            </div>
        </div>
        {/* Ensure the text is consistently left-aligned */}
        <span className="ml-4 font-medium text-gray-700 text-left w-full">{question}</span>
    </div>
);


const FAQSection = () => {
    const [activeIndex, setActiveIndex] = useState(0);

    const handleClick = (index) => {
        setActiveIndex(index);
    };

    return (
        <>
            <div className="max-w-6xl mx-auto">
                <p className="text-4xl md:text-5xl font-bold text-left md:text-center md:pt-16 text-[#003333] pb-10">
                    Frequently Asked Questions
                </p>

                {/* Container for both sections */}
                <div className="relative">
                    {/* Left side: Questions List */}
                    <div className="w-full md:w-3/5 relative z-10 bg-white shadow-md rounded p-4 mt-10">
                        <div className="max-h-[400px] overflow-auto border-r">
                            {questions.map((item, index) => (
                                <FAQItem
                                    key={index}
                                    index={index}
                                    question={item.question}
                                    isActive={activeIndex === index}
                                    onClick={handleClick}
                                />
                            ))}
                        </div>
                    </div>

                    
                    <div className="w-full md:w-3/5 absolute top-1/2 left-1/2 transform -translate-x-2/6 -translate-y-1/2 z-20 shadow-lg rounded-2xl p-6 h-[400px]"
                        style={{ backgroundColor: 'rgba(255, 255, 255, 0.8)' }}>
                        <h2 className="text-xl font-semibold text-gray-800 mb-4">
                            {questions[activeIndex].question}
                        </h2>
                        <p className="text-gray-600">{questions[activeIndex].answer}</p>
                    </div>

                </div>
            </div>
        </>
    );
};

export default FAQSection;
