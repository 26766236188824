import React from 'react'

export default function Section6() {
    return (

        <>
            <div className="container mx-auto p-4 flex justify-center pt-10">
                <table className="rounded rounded-lg">
                    <div className='w-auto border border-gray-400 h-[4rem] flex justify-between p-4 bg-white'>
                        <p className='font-dmMono'>Agri in onboarding</p>
                        <p className='font-dmMono'>1-2 DAYS</p>
                    </div>
                    <div>
                        <tr className='flex flex-row'>
                            <div className="px-4 py-2 border border-gray-400 h-[20rem] w-[25rem] bg-[#EDEEEF]">
                                <div className='bg-white h-[70px] w-[99%] rounded rounded-xl flex flex-row justify-center gap-2 transition-transform transform hover:scale-105 hover:shadow-lg duration-300'>
                                    <div className='flex items-center'>
                                        <img src="/pin.svg" alt="" className=' h-[30px]' />

                                    </div>
                                    <div className='pt-3 text-start'>
                                        <h3 className='font-dmMono'>Enboarding Call</h3>
                                        <p className='text-gray-600'>Getting To Know Your Need</p>
                                    </div>
                                    <div className='flex items-center p-1'>
                                        <img src="/pictures/Meeting.svg" alt="" className='h-[20px]' />

                                    </div>
                                </div>
                            </div>
                            <div className="px-4 py-2 border border-gray-400 h-[20rem] w-[25rem] bg-white flex items-center justify-center">
                                

                                <div className='bg-white h-[70px] w-[99%] rounded-xl flex flex-row justify-center border border-gray-400 gap-2 transition-transform transform hover:scale-105 hover:shadow-lg duration-300'>
                                    <div className='flex items-center'>
                                        <img src="/fire.svg" alt="" className='h-[30px]' />
                                    </div>
                                    <div className='pt-3 text-start'>
                                        <h3 className='font-dmMono'>access and data</h3>
                                        <p className='text-gray-600'>setup space, roles and data</p>
                                    </div>
                                    <div className='flex items-center p-1'>
                                        <img src="/pictures/Setup.svg" alt="" className='h-[20px]' />
                                    </div>
                                </div>

                            </div>
                            <div className="px-4 py-2 border border-gray-400 h-[20rem] w-[25rem] bg-white flex items-end justify-center">
                                <div className='bg-white h-[70px] w-[99%] rounded rounded-xl flex flex-row justify-center border border-gray-400 gap-2 transition-transform transform hover:scale-105 hover:shadow-lg duration-300'>
                                    <div className='flex items-center'>
                                        <img src="/check.svg" alt="" className=' h-[30px]' />

                                    </div>
                                    <div className='pt-3 text-start'>
                                        <h3 className='font-dmMono'>training and support</h3>
                                        <p className='text-gray-600'>we train and support your team</p>
                                    </div>
                                    <div className='flex items-center p-1'>
                                        <img src="/pictures/Done.svg" alt="" className='h-[20px]' />

                                    </div>
                                </div>
                            </div>
                        </tr>

                    </div>
                </table>

            </div>



            <div className='flex flex-row justify-around'>

                <div className='mt-10 bg-white rounded rounded-xl w-[25rem] h-[28rem]'>
                    <div className='p-2'>
                        <div className='text-start bg-gray-200 w-[75px] rounded h-[30px] flex items-center p-2 font-bold'>Etape 1</div>
                        <h1 className='text-start p-2 font-bold text-[#003333]'>prise de contact</h1>
                        <p className='text-start p-2 text-gray-500'>Plannifier un rendez-vous pour discuter de votre besoin et <br /> vous expliquer le rest du processus</p>
                    </div>


                    <div className='flex justify-center pt-8 '>
                        <img src="/pictures/Frame.svg" alt="" className='w-[22rem]' />
                    </div>
                </div>

                <div className='mt-10 bg-white rounded rounded-xl w-[25rem] h-[28rem]'>
                    <div className='p-2'>
                        <div className='text-start bg-gray-200 w-[75px] rounded h-[30px] flex items-center p-2 font-bold'>Etape 2</div>
                        <h1 className='text-start p-2 font-bold text-[#003333]'>Acces et Onboarding</h1>
                        <p className='text-start p-2 text-gray-500'>Nous procédons à vous donner les acces et le setup de votre espace, roles et données </p>
                    </div>


                    <div className='flex justify-center pt-8 '>
                        <img src="/pictures/Frame1.svg" alt="" className='w-[22rem]' />
                    </div>
                </div>

                <div className='mt-10 bg-white rounded rounded-xl w-[25rem] h-[28rem]'>
                    <div className='p-2'>
                        <div className='text-start bg-gray-200 w-[75px] rounded h-[30px] flex items-center p-2 font-bold'>Etape 3</div>
                        <h1 className='text-start p-2 font-bold text-[#003333]'>Support</h1>
                        <p className='text-start p-2 text-gray-500'>Apres que vous commencer d’utiliser Agri In, nous supportons vos equipes via des ateliers et documentation </p>
                    </div>


                    <div className='flex justify-center pt-8 '>
                        <img src="/pictures/Frame2.svg" alt="" className='w-[22rem]' />
                    </div>
                </div>

            </div>




        </>
    )
}
